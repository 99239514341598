import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelQuotedMessageAttachments from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessageAttachments"
import classNames from "classnames"
import { MessageText, useMessageContext } from "stream-chat-react"

const QuotedMessage = () => {
  const { message } = useMessageContext()
  const classes = useStyles()

  const { quoted_message } = message
  if (!quoted_message) return null

  const quotedMessageAttachment = quoted_message.attachments?.length
    ? quoted_message.attachments
    : null

  const quotedMessageAttachmentWithoutLinks = quotedMessageAttachment?.filter(
    (attachment) => !attachment.og_scrape_url
  )

  if (!quoted_message.text && !quotedMessageAttachment) return null

  return (
    <div
      className={classNames(classes.quotedMessage, "quoted-message")}
      data-testid={"quoted-message"}
    >
      <div className={classes.quotedMessageText}>
        <MessageText message={quoted_message} />
      </div>
      {quotedMessageAttachmentWithoutLinks?.length ? (
        <div className={classes.quoteAttachments}>
          <ChatChannelQuotedMessageAttachments
            quotedMessageAttachment={quotedMessageAttachmentWithoutLinks}
          />
        </div>
      ) : null}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  quotedMessage: {
    display: "flex",
    alignItems: "flex-start",
    borderLeft:
      theme.palette.type === "dark"
        ? `2px solid ${theme.palette.groovy.neutral[400]}`
        : `2px solid ${theme.palette.groovy.grey[200]}`,
    paddingLeft: theme.spacing(1),
    flexDirection: "column",
    gap: theme.spacing(1.5),
    position: "relative",
    color: theme.palette.groovy.grey[400],
  },
  quoteAttachments: {
    display: "flex",
    gap: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  quotedMessageText: {
    "& .str-chat__message-text-inner": {
      "& p": {
        margin: 0,
        fontSize: `${theme.typography["body-md"].fontSize} !important`,
        color:
          theme.palette.type === "dark"
            ? `${theme.palette.groovy.neutral[600]} !important`
            : theme.palette.groovy.grey[500],
      },
    },
    "& div": {
      color:
        theme.palette.type === "dark"
          ? `${theme.palette.groovy.neutral[600]} !important`
          : theme.palette.groovy.grey[500],
    },
    "& .str-chat__message-url-link": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.neutral[600]
          : theme.palette.groovy.grey[500],
    },
  },
}))

export default QuotedMessage
